s
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
//import DatePicker from "vue2-datepicker";
import appConfig from "@/app.config";
//import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
//import { format } from "date-fns";
import router from "@/router/index.js";
import { VueEditor } from 'vue2-editor';
/**
 * Orders Component
 */
export default {
  page: {
    title: "Statistiques",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    VueEditor
    /* ckeditor: CKEditor.component, */
  },
  data() {
    return {
      title: "Statistiques",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "Statistiques",
          active: true,
        },
      ],
      value: null,
      value1: null,
      tyMessage: [
        {
          name: "Notification",
          value: 0,
        },
        {
          name: "Mail",
          value: 1,
        },
        {
          name: "Notification & Mail",
          value: 2,
        },
      ],
      tyMedia: [
        {
          name: "",
          value: "",
        },
        {
          name: "Image",
          value: "image",
        },
        {
          name: "Vidéo",
          value: "video",
        },
      ],
      messageSelect: "",
      mediaSelect: "",
      messageContent: [
        {
          sujet: "",
          message: "",
        },
      ],
      sujet: "Demande de vérification",
      url_link: "",
      idUser: 0,
      dataUser: [],
      usersdataId: [],
      totalRows: 1,
      currentPage: 1,

      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      verify: {
        email: false,
        permis: false,
        autoMoto: false,
        image: false,
        tel: false,
        cni: false,
      },
      ordersData: [
        {
          userid: "photo & nom",
          usertype: "CONDUCTEUR",
          piece: "PERMIS",
        },
        {
          userid: "photo & nom",
          usertype: "PARTICULIER",
          piece: "NUMERO DE TELEPHONE",
        },
        {
          userid: "photo & nom",
          usertype: "CONDUCTEUR",
          piece: "CNI",
        },
        {
          userid: "photo & nom",
          usertype: "PARTICULIER",
          piece: "PROFILE",
        },
      ],
      fields: [
        { key: "username", label: "Utilisateur", sortable: true },
        { key: "tel", label: "Téléphone", sortable: true },
        { key: "userType", sortable: true, label: "Type d'Utilisateur" },
        { key: "piece", sortable: true, label: "Pièces non vérifiées" },
        { key: "action", label: "Actions" },
      ],
      sujet_message: "",
      message: null,
      rowValue: "",
      messagecni: null,
      messageauto: null,
      messagepermis: null,
      updateValue: "",
      showDelete: false,
      showEchec: false,
      showSucess: false,
      showEdit: false,
      showModal: false,
      showPassager: false,
      profiList: false,
      cniList: false,
      vehiculeList: false,
      telList: false,
      all: false,
      test: null,
      conducteur: [],
      checkedPieces: [],
      editor: ClassicEditor,
      editorData: "Salut veuillez faire vérifier les informations suivantes /n",
      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDataStatistique: [],
      recherche:"",
      tableForMail:[],
      conducteurChoose:[],
      passagerChoose:[]
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length;
    },
  },


/*


 userTypeList: [
        { 
        id:  "CONDUCTEUR",
        value:"MOTO"
      
      },
        {
         id: "PARTICULIER",
       value: "VOITURE"
      },
        {
          id:"TAXIMAN", value:"TAXI"
        },
        {id:"SOCIETE",
        
        value:"BUS"
      },
        {id:"PASSAGER",
        value:"PASSAGER"
      },
        
      ],



*/










  async mounted() {
    //Chargement des données
    const data = await apiRequest(
      "GET",
      "admin/users-no-verif",
      undefined,
      false
    );
    if (data && data.data) {
      this.totalValue=data.data.total
 
      const formattedTable = data.data.users.map((user) => {
        var pieces = {
          automoto: user.autoMotoVerified == false ? "- AUTO MOTO" : "",
          cni: user.cniVerified == false ? "- CNI" : "",
          picture: user.pictureVerified == false ? "- PHOTO PROFIL" : "",
          permis: user.permisVerified == false ? "- PERMIS" : "",
        };
        return {
          id: user.id,
          username: user.lastname + " " + user.firstname,
        //  userType: user.userType ? user.userType=="CONDUCTEUR"?"MOTO": user.userType=="PARTICULIER"?"VOITURE":user.userType=="TAXIMAN"?"TAXI":user.userType=="SOCIETE"?"BUS":user.userType=="UNDEFINED": "UNDEFINED", ,
          userType: user.userType ,
          tel: user?`${user.indicatifTel}${user.tel}`:"",


          piece:
            pieces.automoto +
            " -- " +
            pieces.cni +
            " -- " +
            pieces.picture +
            " -- " +
            pieces.permis,
        };
      });
      this.dataUser = formattedTable;
      this.totalRows=formattedTable.length
    }
    // Set the initial number of items

  },
  methods: {
    EditModal() {
      this.submitted = false;
      this.showEdit = false;
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    sendToDriverModal(){
      this.tableForMail=[]
      this.conducteurChoose=[]
      console.log(JSON.stringify(this.newDataStatistique));
      this.tableForMail=this.newDataStatistique.length!=0?this.newDataStatistique:this.dataUser
      this.Refresh();
      this.usersdataId = [];
      for (let i = 0; i <  this.tableForMail.length; i++) {
        if ( this.tableForMail[i].userType != "PASSAGER") {
          this.usersdataId.push( this.tableForMail[i].id);
          this.conducteurChoose.push(this.tableForMail[i])
        }
      }
      this.showModal = true;
      console.log( JSON.stringify( this.conducteurChoose ));
    },

    sendToPassengerModal(){
      this.passagerChoose=[]
      console.log(JSON.stringify(this.newDataStatistique));
      this.tableForMail=[]
      this.tableForMail=this.newDataStatistique.length!=0?this.newDataStatistique:this.dataUser
      this.Refresh();
      this.usersdataId = [];
      for (let i = 0; i <  this.tableForMail.length; i++) {
        if ( this.tableForMail[i].userType == "PASSAGER") {
          this.usersdataId.push( this.tableForMail[i].id);
          this.passagerChoose.push(this.tableForMail[i])
        }
      }
      this.showModal = true;
  

      console.log( JSON.stringify(this.passagerChoose ));
    },

    openNewTab(row) {
      window.open(`https://adminrmobility.raynis.co/user/user_info/${row.id}`, '_blank');
    },

   /*  async SendToDriver() {
      this.test = "conducteur";
      this.Refresh();
      this.usersdataId = [];
      for (let i = 0; i < this.dataUser.length; i++) {
        if (this.dataUser[i].userType == "MOTO") {
          this.usersdataId.push(this.dataUser[i].id);
        }
      }


      const message = await apiRequest(
        "POST",
        "admin/message-users",
        {
          idUsers: this.usersdataId,
          typeMessage: this.messageSelect.value,
          subject: this.sujet_message,
          text: this.editorData,
          media_type: this.tyMedia.value ? this.tyMedia.value : "",
          url: this.url_link,
        },
        false
      );
      if (message && message.data) {
        this.showMessage = false;

        this.$snotify.success("Message envoyé avec succès !!!", "Fait");
      } else {
        this.showEchec = true;
      }
    }, */
   /*  async SendToPassenger() {
      this.test = "passager";
      this.Refresh();
      this.usersdataId = [];
      for (let i = 0; i < this.dataUser.length; i++) {
        if (this.dataUser[i].userType != "PASSAGER") {
          this.usersdataId.push(this.dataUser[i].id);
        }
      }

      this.showModal = true;

      const message = await apiRequest(
        "POST",
        "admin/message-users",
        {
          idUsers: this.usersdataId,
          typeMessage: this.messageSelect.value,
          subject: this.sujet_message,
          text: this.editorData,
          media_type: this.tyMedia.value ? this.tyMedia.value : "",
          url: this.url_link,
        },
        false
      );
      if (message && message.data) {
        this.showMessage = false;

        this.$snotify.success("Message envoyé avec succès !!!", "Fait");
      } else {
        this.showEchec = true;
      }
    }, */

    AllChecked() {
      if (this.all == false) {
        this.messagecni = true;

        this.message = true;
        this.editorData = [];
        this.editorData =
          "Salut veuillez faire vérifier les informations suivantes \n";
        this.checkedPieces = [];
        this.checkedPieces.push("- PROFIL");
        this.checkedPieces.push("- CNI");

        if (this.test == "conducteur") {
          this.checkedPieces.push("- AUTO/MOTO");
          this.checkedPieces.push("- PERMIS");
          this.messageauto = true;
          this.messagepermis = true;
        }
        this.update();
      } else {
        this.messagecni = false;
        this.messageauto = false;
        this.messagepermis = false;
        this.message = false;
        this.checkedPieces = [];
        this.editorData = [];
        this.update();
      }
    },

    goToDetail(row) {
      router.push(`/user/verification/${row.id}`);
      //router.push('/user/user_info/'+row.id)
    },
    update() {
      this.updateValue = "";
      if (this.checkedPieces.length > 0) {
        this.editorData =
          "Salut veuillez faire vérifier les informations suivantes"+"\n";
        for (let i = 0; i < this.checkedPieces.length; i++) {
          this.updateValue +="<p>"+ this.checkedPieces[i]+"</p>" ;
        }
        this.editorData += this.updateValue;
      } else {
        this.editorData =
          "Salut veuillez faire vérifier les informations suivantes"+"<p></p>";
      }
    },
    changeProfil() {
      if (this.message != true) {
        if (this.checkedPieces.length > 0) {
          for (let i = 0; i < this.checkedPieces.length; i++) {
            if (this.checkedPieces[i] == "- PROFIL") {
              console.log("");
            } else {
              this.checkedPieces.push("- PROFIL");
              this.update();
              break;
            }
          }
        } else {
          this.checkedPieces.push("- PROFIL");
          this.update();
        }
      } else {
        this.all = false;
        if (this.checkedPieces.length > 0) {
          for (let i = 0; i < this.checkedPieces.length; i++) {
            if (this.checkedPieces[i] == "- PROFIL") {
              this.checkedPieces.splice(i, 1);
              this.update();
            }
          }
        }
      }
    },
    changeCni() {
      if (this.messagecni != true) {
        if (this.checkedPieces.length > 0) {
          for (let i = 0; i < this.checkedPieces.length; i++) {
            if (this.checkedPieces[i] == "- CNI") {
              console.log("");
            } else {
              this.checkedPieces.push("- CNI");
              this.update();
              break;
            }
          }
        } else {
          this.checkedPieces.push("- CNI");
          this.update();
        }
      } else {
        this.all = false;
        if (this.checkedPieces.length > 0) {
          for (let i = 0; i < this.checkedPieces.length; i++) {
            if (this.checkedPieces[i] == "- CNI") {
              this.checkedPieces.splice(i, 1);
              this.update();
            }
          }
        }
      }
    },
    changeAuto() {
      if (this.test == "conducteur") {
        if (this.messageauto != true) {
          if (this.checkedPieces.length > 0) {
            for (let i = 0; i < this.checkedPieces.length; i++) {
              if (this.checkedPieces[i] == "- AUTO/MOTO") {
                console.log("");
              } else {
                this.checkedPieces.push("- AUTO/MOTO");
                this.update();
                break;
              }
            }
          } else {
            this.checkedPieces.push("- AUTO/MOTO");
            this.update();
          }
        } else {
          this.all = false;
          if (this.checkedPieces.length > 0) {
            for (let i = 0; i < this.checkedPieces.length; i++) {
              if (this.checkedPieces[i] == "- AUTO/MOTO") {
                this.checkedPieces.splice(i, 1);
                this.update();
              }
            }
          }
        }
      }
    },
    changePermis() {
      if (this.all == "conducteur") {
        if (this.messagepermis != true) {
          if (this.checkedPieces.length > 0) {
            for (let i = 0; i < this.checkedPieces.length; i++) {
              if (this.checkedPieces[i] == "- PERMIS") {
                console.log("");
              } else {
                this.checkedPieces.push("- PERMIS");
                this.update();
                break;
              }
            }
          } else {
            this.checkedPieces.push("- PERMIS");
            this.update();
          }
        } else {
          this.all = false;
          if (this.checkedPieces.length > 0) {
            for (let i = 0; i < this.checkedPieces.length; i++) {
              if (this.checkedPieces[i] == "- PERMIS") {
                this.checkedPieces.splice(i, 1);
                this.update();
              }
            }
          }
        }
      }
    },
    Refresh() {
      this.showModal = true;
      this.checkedPieces = [];
      this.message = null;
      this.messageauto = null;
      this.messagepermis = null;
      this.messagecni = null;
      this.editorData =
        "Salut veuillez faire vérifier les informations suivante \n";
    },
    fonctionRow(row) {
      this.Refresh();
      this.rowValue = row;
    },
    async addMessage() {
      this.showMessage = false;
      const data = await apiRequest(
        "POST",
        "admin/message-user",
        {
          idUser: this.usersdataId,
          typeMessage: this.messageSelect.value,
          subject: this.sujet,
          text: this.editorData,
          media_type: this.tyMedia.value ? this.tyMedia.value : "",
          url: this.url_link,
        },
        false
      );

      if (data && data.data) {


        this.$toast.success("Message envoyé avec succès !!!", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
      } else {
        this.showEchec = true;
      }
    },
    async addMessagePassagerOrConducteur() {
      this.showMessage = false;
      const data = await apiRequest(
        "POST",
        "admin/message-users",
        {
          idUsers: this.usersdataId,
          typeMessage: this.messageSelect.value,
          subject: this.sujet,
          text: this.editorData,
          media_type: this.tyMedia.value ? this.tyMedia.value : "",
          url: this.url_link,
        },
        false
      );

      if (data && data.data) {
        this.$toast.success("Message envoyé avec succès !!!", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
      } else {
        this.showEchec = true;
      }
    },

    async actif(page, limit) {
      //Chargement des données

      const data = await apiRequest(
        "GET",
        "admin/users-no-verif?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      if (data && data.data) {
     
        const formattedTable = data.data.users.map((user) => {
        var pieces = {
          automoto: user.autoMotoVerified == false ? "- AUTO MOTO" : "",
          cni: user.cniVerified == false ? "- CNI" : "",
          picture: user.pictureVerified == false ? "- PHOTO PROFIL" : "",
          permis: user.permisVerified == false ? "- PERMIS" : "",
        };
        return {
          id: user.id,
          username: user.lastname + " " + user.firstname,
        
          tel: user?`${user.indicatifTel}${user.tel}`:"",

          userType: user.userType ,
          piece:
            pieces.automoto +
            " -- " +
            pieces.cni +
            " -- " +
            pieces.picture +
            " -- " +
            pieces.permis,
        };
      });
        this.newDataStatistique = formattedTable;
        this.userChargeLoader = false;
        this.totalRows = formattedTable.length;
      }
    },

   

    async userSearch() {
      console.log(this.recherche);
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "GET",
        "admin/users-no-verif/recherche/?mots="+this.recherche+"&page="+ this.startValue + "&limit=" + this.endValue,

    undefined,
        false
      );
      if (dataSearch && dataSearch.data) {

        const formattedTable = dataSearch.data.map((user) => {
        var pieces = {
          automoto: user.autoMotoVerified == false ? "- AUTO MOTO" : "",
          cni: user.cniVerified == false ? "- CNI" : "",
          picture: user.pictureVerified == false ? "- PHOTO PROFIL" : "",
          permis: user.permisVerified == false ? "- PERMIS" : "",
        };
        return {
          id: user.id,
          username: user.lastname + " " + user.firstname,
          userType: user.userType ,
          tel: user?`${user.indicatifTel}${user.tel}`:"",

          piece:
            pieces.automoto +
            " -- " +
            pieces.cni +
            " -- " +
            pieces.picture +
            " -- " +
            pieces.permis,
            
        };
      });
        this.newDataStatistique = formattedTable;
        this.totalRows = formattedTable.length;
    

        this.userChargeLoader = false;
      }
    },

    decrement() {
      this.userChargeLoader = true;

      this.startValue -= 500;
      this.endValue -= 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += 500;
      this.endValue += 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - 500;
      this.endValue = this.totalValue;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },

    





  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="containerDot" v-if="dataUser.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div
            class="d-flex align-items-center otherThing"
            v-if="userChargeLoader === true"
          >
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body">
           <div class="p-2">

      
              
                <div
              class="row d-flex justify-content-between align-items-center p-2"
            >
           <div>Total d'utilisateurs: {{ totalRows }}</div>
             
              <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == 500"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
                <div class="row d-flex justify-content-between align-items-center mt-4 mb-2">
                 
                  <div class=" p-3">
                    <button class="btn bg-green" @click="sendToDriverModal">
                      Envoyer aux conducteurs
                    </button>
                    <button
                      class="btn bg-red ml-3"
                      @click="sendToPassengerModal"
                    >
                      Envoyer aux passagers
                    </button>
                  
                  </div>
                  <!-- Search -->
                  <div class="inputCol pr-3 ">
                    <div class="input">
                      <input
                        type="search"
                        id="serchInput"
                        placeholder="Rechercher..."
                        v-model="recherche"
                        @keyup.enter="userSearch"
                      />
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="newDataStatistique.length!=0?newDataStatistique:dataUser"
                    :fields="fields"
                    responsive="sm"

                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    <template v-slot:cell(badge)="row">
                      <div
                        class="text-center"
                        :class="{
                          'fa fa-star': `${row.value}` === 'false',
                          'fa fa-star badge-soft-warning':
                            `${row.value}` === 'true',
                        }"
                      ></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div
                        class="badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Suspendu',
                          'badge-soft-success': `${row.value}` === 'Actif',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                <template v-slot:cell(action)="row">
                  <a
                        href="javascript:void(0);"
                        class="text-primary ml-2"
                        @click="
                         openNewTab(row.item)
                        "
                        v-b-tooltip.hover
                        title="Détail"
                      >
                      <i class="fa fa-eye" aria-hidden="true"></i>
                      </a>
                    </template> 
                    

                    <template v-slot:cell(tel)="row" style="text-align: center">
                      <a
                        :href="'https://wa.me/' + row.value"
                        class="text-success"
                        v-b-tooltip.hover
                        title="Envoyer un message whatsapp"
                        target="_blank"
                      >
                        {{ row.value }}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
              <div class="col">
                <!--   <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      :aria-controls="trajet"
                    ></b-pagination>
                  </ul>
                </div> -->
                <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == 500"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <!-- -->
    <b-modal v-model="showModal" title="Nouveau Message" centered>
      <div class="row mb-3">
        <div class="col-md-3">
          <input
            type="checkbox"
            value="PROFIL"
            v-model="message"
            @click="changeProfil()"
          />
          <span class="ml-1">PROFIL</span>
        </div>
        <div class="col-md-2">
          <input
            type="checkbox"
            value="CNI"
            v-model="messagecni"
            @click="changeCni()"
          />
          <span class="ml-1">CNI</span>
        </div>
        <div class="col-md-4">
          <input
            type="checkbox"
            value="AUTO/MOTO"
            v-model="messageauto"
            @click="changeAuto()"
          />
          <span class="ml-1">AUTO/MOTO</span>
        </div>
        <div class="col-md-3">
          <input
            type="checkbox"
            value="PERMIS"
            v-model="messagepermis"
            @click="changePermis()"
          />
          <span class="ml-1">PERMIS</span>
        </div>
        <h6></h6>
        <div class="col-md-3">
          <input
            type="checkbox"
            value="Cocher"
            v-model="all"
            @click="AllChecked()"
          />
          <span class="ml-1">TOUT</span>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label">Choisir le type de message:</label>
        <multiselect
          v-model="messageSelect"
          :options="tyMessage"
          label="name"
          track-by="value"
        ></multiselect>
      </div>
      <form>
        <div class="form-group">
          <input
            type="text"
            v-model="sujet_message"
            class="form-control"
            placeholder="Sujet"
          />
        </div>
        <div class="form-group">
          <!-- <ckeditor v-model="editorData" :editor="editor"></ckeditor> -->
        <!--   <textarea
            class="form-control"
            v-model="editorData"
            id=""
            cols="30"
            rows="10"
          ></textarea> -->
          <div class="editor-wrapper">
    <vue-editor v-model="editorData"></vue-editor>
{{editorData}}
  </div>
        </div>
        <div class="form-group">
          <label class="control-label">Choisir le type de média:</label>
          <multiselect
            v-model="mediaSelect"
            :options="tyMedia"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model="url_link"
            placeholder="Lien du média"
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModal = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="addMessagePassagerOrConducteur">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showSucess"
      title="Message envoyé avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showSucess = false">OK</b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchec"
      title="L'envoie a échoué"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchec = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>
  </Layout>
</template>
<style>
thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}

.input {
display: flex;
justify-self: left;
  position: relative;

  width: 260px;
  height: 35px;

  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
 
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
} .paging_simple_numbers ul li{
  color:white!important;
}


.paging_simple_numbers ul li button{
  color:#fff!important;
}


.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}
.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
}
.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: -16%;
  left: 0;
  right: 0;
  margin: 0 auto;
}


br {
        line-height:10px;
     }



     .bg-red{
  background:#dc143c;
  color: #fff;
} 



.bg-green{
  background  :#3AAA35;
  color: #fff;
}


</style>
